import BaseTemplate from "../../Templates/BaseTemplate/BaseTemplate"
import { Welcome } from "../../Organisms"

export default function HomeLoggedOut() {
	return (
		<BaseTemplate>
			<Welcome />
		</BaseTemplate>
	)
}
