import * as S from './styles'
import { useContext } from 'react'
import { MainContext } from '../../../context/MainContext/MainContext'
import { SelectGrowth, Welcome } from '../../Molecules'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import logo from '../../../assets/logo.png'
import ModalGrowth from '../../Molecules/ModalGrowth/modalGrowth'
import NewGrowth from '../../Molecules/NewGrowth/newGrowth'

const Header = () => {
  const { login, showMenu, setShowMenu } = useContext(MainContext)

  return (
    <S.HeaderStyled>
      <h1><img src={logo} alt='Grow App' /></h1>

      {
        login.isLoggedIn ? (
          <>
            <nav className={showMenu ? 'opened' : 'closed'}>
              <SelectGrowth />
              <ModalGrowth />
              <NewGrowth />
              <Welcome />
            </nav>

            <S.MenuButtonStyled className={showMenu ? 'opened' : 'closed'} onClick={() => setShowMenu(!showMenu)}>
              {
                showMenu ? (
                  <ArrowForwardIosIcon />
                ) : (
                  <MenuIcon />
                )
              }
            </S.MenuButtonStyled>
          </>
        ) : (
          <></>
        )
      }
    </S.HeaderStyled>
  )
}

export default Header
