import * as S from './styles'
import { useContext, useState } from 'react'
import { GrowthContext } from '../../../context/GrowthContext/GrowthContext'
import { MainContext } from '../../../context/MainContext/MainContext'
import updateGrowth from '../../../hooks/use-update-growth'
import deleteGrowth from '../../../hooks/use-delete-growth'

const ModalGrowth = () => {
  const { setIsPageLoading } = useContext(MainContext)
  const { selectedGrowth, _growthRefetch } = useContext(GrowthContext)
  const [isSoilHidden, setIsSoilHidden] = useState<boolean>(true)

  const handleUpdateGrowth = (ev: any) => {
    ev.preventDefault()
    setIsPageLoading(true)

    var data = new FormData(ev.target)
    let growthForm: any = {}
    data.forEach((d, i) => {
      growthForm[i] = d
    })

    updateGrowth(growthForm, selectedGrowth.growth_id).then((response) => {
      if (response.data.length) {
        _growthRefetch()
        setIsPageLoading(false)
      }
    })
  }

  const handleDeleteGrowth = () => {
    if (window.confirm(`Tem certeza que deseja apagar  ${selectedGrowth.growth_name}?`)) {
      setIsPageLoading(true)

      deleteGrowth(selectedGrowth.growth_id).then(() => {
        _growthRefetch().then(() => {
          setIsPageLoading(false)
          setIsSoilHidden(true)
        })
      })
    }
  }

  return (
    selectedGrowth.growth_id ? (
      <S.ModalGrowthStyled>
        <button onClick={() => setIsSoilHidden(!isSoilHidden)}>
          {`Detalhes do Cultivo`}
        </button>

        <S.ModalStyled hidden={isSoilHidden}>
          <S.ModalFormStyled onSubmit={handleUpdateGrowth}>
            <button className='close' type='button' onClick={() => setIsSoilHidden(true)}>X</button>

            <div>
              <input type='text' name='growth_name' defaultValue={selectedGrowth.growth_name} />
            </div>
            <div>
              <textarea name='growth_soil' defaultValue={selectedGrowth.growth_soil}></textarea>
            </div>

            <div className='actions'>
              <button className='rmvbtn' onClick={handleDeleteGrowth}>Apagar Cultivo</button>
              <button type='submit'>Alterar</button>
            </div>
          </S.ModalFormStyled>
        </S.ModalStyled>
      </S.ModalGrowthStyled>
    ) : (
      <></>
    )
  )
}

export default ModalGrowth
