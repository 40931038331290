import * as S from './styles'
import { useContext, useEffect, useState } from 'react'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import VerifiedIcon from '@mui/icons-material/Verified'
import { GrowthContext } from '../../../context/GrowthContext/GrowthContext'
import { MainContext } from '../../../context/MainContext/MainContext'
import updateCurrentGrowth from '../../../hooks/use-update-current-growth'

const SetCurrentGrowth = () => {
  const { setIsPageLoading } = useContext(MainContext)
  const { selectedGrowth, _growthRefetch } = useContext(GrowthContext)
  const [isCurrent, setIsCurrent] = useState<boolean>(selectedGrowth.growth_current === 1)

  const handleUpdateCurrentGrowth = (current: boolean) => {
    if (!current) {
      setIsPageLoading(true)
      updateCurrentGrowth(selectedGrowth.growth_id).then((data) => {
        if (data.success) {
          _growthRefetch()
        }
        setIsPageLoading(false)
      })
    }

    return false
  }

  useEffect(() => {
    setIsCurrent(selectedGrowth.growth_current === 1)
  }, [selectedGrowth])

  return (
    selectedGrowth.growth_id ? (
      <S.SetCurrentGrowthStyled>
        <button
          onClick={() => handleUpdateCurrentGrowth(isCurrent)}
          className={isCurrent ? 'current' : ''}
          data-tooltip-id={`growth_${selectedGrowth.growth_id}`}
          data-tooltip-place="left"
        >
          {
            isCurrent ? (
              <VerifiedIcon />
            ) : (
              <NewReleasesIcon />
            )
          }
        </button>

        <S.TooltipStyled id={`growth_${selectedGrowth.growth_id}`} place="left">
          {
            selectedGrowth.growth_current === 1 ? (
              'Esse é seu cultivo principal!'
            ) : (
              'Deseja tornar esse o seu cultivo principal?'
            )
          }
        </S.TooltipStyled>
      </S.SetCurrentGrowthStyled>
    ) : (
      <></>
    )
  )
}

export default SetCurrentGrowth
