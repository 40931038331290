import { useQuery } from 'react-query'
import { api } from '../../services/api'
import { PointInterface } from '../../interfaces/Point'

async function fetchGetPoint(plantId: number): Promise<PointInterface> {
	const { data } = await api.get<PointInterface>(`points/${plantId}`)
	return data
}

export function useGetPoint(plantId: number = 0) {
	const { data, isLoading, error, refetch } = useQuery(
		`points/${plantId}`,
		() => fetchGetPoint(plantId),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: false,
			enabled: false,
			retry: false
		}
	)

	return {
		_pointResponse: data,
		_pointLoading: isLoading,
		_pointError: error,
		_pointRefetch: refetch
	}
}
