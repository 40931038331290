import * as S from './styles'
import { FormLogin, FormRegister } from '../../Molecules'

const Welcome = () => {
  return (
    <S.WelcomeStyled>
      <h2>Seja bem-vindo!</h2>

      <S.GatewayStyled>
        <FormRegister />
        <FormLogin />
      </S.GatewayStyled>
    </S.WelcomeStyled>
  )
}

export default Welcome
