import * as S from './styles'
import { useContext } from 'react'
import { GrowthContext } from '../../../context/GrowthContext/GrowthContext'
import { GrowthType } from '../../../interfaces/Growth'
import SetCurrentGrowth from '../SetCurrentGrowth/setCurrentGrowth'

const SelectGrowth = () => {
  const { growths, selectedGrowth, handleSelectedGrowth } = useContext(GrowthContext)

  const handleChangeGrowth = (ev: any) => {
    const selected = growths?.filter((g: GrowthType) => {
      return g.growth_id === parseInt(ev.target.value)
    })

    if (selected.length) {
      handleSelectedGrowth(selected[0])
    }
  }

  const options = growths.length ? (
    growths?.map((g: GrowthType, i: number) => {
      return (
        <option key={i} value={g.growth_id}>{g.growth_name}</option>
      )
    })
  ) : (
    <></>
  )

  return (
    selectedGrowth.growth_id ? (
      <S.SelectGrowthStyled>
        <SetCurrentGrowth />
        <S.SelectStyled name='growth' value={selectedGrowth.growth_id} onChange={handleChangeGrowth}>
          <option>Selecione um cultivo</option>
          {options}
        </S.SelectStyled>
      </S.SelectGrowthStyled>
    ) : (
      <></>
    )
  )
}

export default SelectGrowth
