import * as S from './styles'
import PlantMenu from '../PlantMenu/plantMenu'
import PlantContent from '../PlantContent/plantContent'

const Growth = () => {
  return (
    <S.GrowthStyled>
      <PlantMenu />
      <PlantContent />
    </S.GrowthStyled>
  )
}

export default Growth
