import * as S from './styles'
import { useContext } from "react"
import { useGetLogout } from "../../../hooks/use-get-logout"
import { MainContext } from "../../../context/MainContext/MainContext"

const Welcome = () => {
  const { login, _loginRefetch } = useContext(MainContext)
  const { _logoutRefetch } = useGetLogout()

  return (
    <S.WelcomeStyled>
      <p>Bem vindo(a)<br /><strong>{login.userName}</strong></p>
      {/* <button>Editar</button> */}
      <button type='button' onClick={() => {
        _logoutRefetch().then(() => {
          _loginRefetch()
        })
      }}>Sair</button>
    </S.WelcomeStyled>
  )
}

export default Welcome
