/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { MainContext } from '../MainContext/MainContext'
import { GrowthInterface, GrowthType } from '../../interfaces/Growth'
import { useGetGrowth } from '../../hooks/use-get-growth'

type GrowthProviderProps = {
	children: ReactNode
}

type GrowthContextData = {
	_growthRefetch: () => Promise<QueryObserverResult<GrowthInterface>>
	growths: GrowthType[]
	selectedGrowth: GrowthType
	handleSelectedGrowth: (growth: GrowthType) => void
}

const GrowthContext = createContext({} as GrowthContextData)

const GrowthProvider = ({ children }: GrowthProviderProps) => {
	const { login, setShowMenu } = useContext(MainContext)
	const { _growthResponse, _growthLoading, _growthRefetch } = useGetGrowth()
	const [growths, setGrowths] = useState<GrowthType[] | []>([])
	const [selectedGrowth, setSelectedGrowth] = useState<GrowthType>(Object)
	const savedGrowth = localStorage.getItem('growth') || null

	const handleSelectedGrowth = (growth: GrowthType): void => {
		setSelectedGrowth(growth)
		setShowMenu(false)
		localStorage.setItem('growth', growth.growth_id.toString())
	}

	useEffect(() => {
		if (!_growthLoading && _growthResponse?.data.length) {
			const current = _growthResponse.data.filter((g: GrowthType) => {
				if (savedGrowth) {
					return parseInt(savedGrowth) === g.growth_id
				}

				return g.growth_current === 1
			})

			let currentGrowth = current.length ? current[0] : _growthResponse.data[_growthResponse.data.length - 1]

			setGrowths(_growthResponse.data)
			handleSelectedGrowth(currentGrowth)
		}
	}, [_growthResponse, _growthLoading, savedGrowth])

	useEffect(() => {
		if (login.isLoggedIn) {
			_growthRefetch()
		}
	}, [login, _growthRefetch])

	return (
		<GrowthContext.Provider
			value={{
				_growthRefetch,
				growths,
				selectedGrowth,
				handleSelectedGrowth
			}}
		>
			{children}
		</GrowthContext.Provider>
	)
}

export { GrowthProvider, GrowthContext }
