import styled from 'styled-components'
import media from 'styled-media-query'

export const ModalPointStyled = styled.div`
	form {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.field {
		align-items: center;
		display: grid;
		gap: 4px;
		grid-template-columns: 25% 75%;
	}

	.image {
		display: grid;
		gap: 4px;
		grid-template-columns: 25% 75%;

		span {
			background: ${({ theme }) => theme.colors.first};
			border: 1px solid ${({ theme }) => theme.colors.main};
			padding: 0.5rem;

			img {
				display: block;
			}
		}
	}

	label {
		font-size: 15px;
		line-height: 21px;
		text-align: right;
	}

	input {
		background: #bfc9ce30;
		color: ${({ theme }) => theme.colors.text.light};
		border: 0;
		font-size: 15px;
		font-weight: 400;
		padding: 0.5rem;
		width: 100%;
	}
	
	& > button {
		width: 100%;
		
		${media.lessThan('medium')`
			width: 100%;
		`}
	}
`