import { api } from '../../services/api'
import { LoginPostType } from '../../interfaces/Login'
import { UserInterface } from '../../interfaces/User'
// import { convertToBase64 } from '../../utils/convertToBase64'

const postLogin = async (form: LoginPostType): Promise<UserInterface> => {
	// const emailBase64 = convertToBase64(email)
	// const passwordBase64 = convertToBase64(password)

	const { data } = await api
		.post(`login`, form)
		.then((response) => response)

	return data
}

export default postLogin
