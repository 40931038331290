export const convertToBRDate = (_value: Date, format: 'basic' | 'full' | 'input' | 'week' = 'basic'): string => {
  const dateToConvert = new Date(_value.toString())

  if (format === 'week') {
    return dateToConvert.toLocaleString('pt-br', { weekday: 'narrow' })
  }

  if (format === 'full') {
    return dateToConvert.toLocaleString('pt-br', { dateStyle: 'short' })
  }

  if (format === 'input') {
    return dateToConvert.toLocaleDateString('pt-br').split('/').reverse().join('-')
  }


  return dateToConvert.toLocaleString('pt-br', { day: '2-digit', month: 'short' }).replace('.', '')
}
