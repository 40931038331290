import BaseTemplate from "../../Templates/BaseTemplate/BaseTemplate"
import { Growth } from "../../Organisms"

export default function HomeLoggedIn() {
	return (
		<BaseTemplate>
			<Growth />
		</BaseTemplate>
	)
}
