import styled from 'styled-components'
import media from 'styled-media-query'

export const WelcomeStyled = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	
	h2 {
		color: ${({ theme }) => theme.colors.text.light};
		margin: 1rem 0 2.5rem;
	}
`

export const GatewayStyled = styled.div`
	align-items: stretch;
	display: flex;
	gap: 32px;
	width: 100%;
	justify-content: center;
	padding: 2.5rem;

	${media.lessThan('medium')`
		align-items: center;
		flex-direction: column;
		padding: 0.5rem;
	`}
`
