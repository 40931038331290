import { useQuery } from 'react-query'
import { api } from '../../services/api'
import { PlantInterface } from '../../interfaces/Plant'

async function fetchGetPlant(growthId: number): Promise<PlantInterface> {
	const { data } = await api.get<PlantInterface>(`plants/${growthId}`)
	return data
}

export function useGetPlant(growthId: number = 0) {
	const { data, isLoading, error, refetch } = useQuery(
		`plants/${growthId}`,
		() => fetchGetPlant(growthId),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: false,
			enabled: false,
			retry: false
		}
	)

	return {
		_plantResponse: data,
		_plantLoading: isLoading,
		_plantError: error,
		_plantRefetch: refetch
	}
}
