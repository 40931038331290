import * as S from './styles'
import { useContext } from 'react'
import { PointContext } from '../../../context/PointContext/PointContext'
import { PlantType } from "../../../interfaces/Plant"
import { WeekPeriod } from "../../Molecules"

type PlantDetailsType = {
  plant: PlantType
  period: 'v' | 'b'
}

const PlantPeriod = (props: PlantDetailsType) => {
  const { plant, period } = props
  const { points } = useContext(PointContext)

  /**
   * Calculate start bloom date
   */
  let plant_start = new Date(plant.plant_start_vega)
  let plant_bloom = plant_start.setDate(plant_start.getDate() + plant.plant_cycle_vega)

  const plant_start_vega = new Date(plant.plant_start_vega)
  const plant_start_bloom = new Date(plant_bloom)

  const plantPeriod = {
    'v': {
      title: "Vega",
      start: plant_start_vega,
      total: plant.plant_cycle_vega
    },
    'b': {
      title: "Flora",
      start: plant_start_bloom,
      total: plant.plant_cycle_bloom
    }
  }

  const WeekStartDate = (start: Date, total: number) => {
    const weeks = new Array(Math.ceil(total / 7))

    for (var i = 0; i < weeks.length; i++) {
      const init = start.setDate(start.getDate() + (i > 0 ? 7 : 0))
      weeks[i] = new Date(init)
    }

    return weeks
  }

  const weeks = WeekStartDate(plantPeriod[period].start, plantPeriod[period].total)

  return (
    <S.PeriodStyled>
      <h2>{plantPeriod[period].title}</h2>
      <div className='weeks'>
        {
          weeks.map((d: Date, i: number) => (
            <WeekPeriod key={i} period={period} start={d} weekNum={i} total={plantPeriod[period].total} points={points} />
          ))
        }
      </div>
    </S.PeriodStyled>
  )
}

export default PlantPeriod
