import axios from 'axios'

const isProdEnv = true
const urlProd = 'https://grow.app.br/api/'
// const urlLocal = 'http://localhost:21146/api/'
const urlLocal = 'http://grow.app.br/api/'

if (isProdEnv) {
	axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept'
	axios.defaults.headers.post['Cache-Control'] = 'no-cache'
}

export const api = axios.create({
	withCredentials: true,
	baseURL: isProdEnv ? urlProd : urlLocal
})