import * as S from './styles'
import { useContext, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { MainContext } from '../../../context/MainContext/MainContext'
import { convertToBRDate } from '../../../utils/convertToBRDate'
import { PlantContext } from '../../../context/PlantContext/PlantContext'
import { PointContext } from '../../../context/PointContext/PointContext'
import ModalGeneral from '../ModalGeneral/modalGeneral'
import deletePoint from '../../../hooks/use-delete-point'
import { PointType } from '../../../interfaces/Point'
import updatePoint from '../../../hooks/use-update-point'

type ModalPointType = {
  point: PointType
}

const ModalEditPoint = (props: ModalPointType) => {
  const { point } = props
  const { setIsPageLoading } = useContext(MainContext)
  const { selectedPlant } = useContext(PlantContext)
  const { setPoints, _pointRefetch } = useContext(PointContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [image, setImage] = useState({
    preview: '',
    raw: '',
  })

  const handleEditPoint = (ev: any) => {
    ev.preventDefault()
    setPoints([])
    setIsPageLoading(true)

    var data = new FormData(ev.target)
    let pointForm: any = {}
    data.forEach((d, i) => {
      pointForm[i] = d
    })

    updatePoint(pointForm, point.point_id).then((response) => {
      console.log(response)
      if (response.data.length) {
        _pointRefetch().then(() => {
          setIsPageLoading(false)
          setShowModal(false)
        })
      }
    })
  }

  const handleDeletePoint = (id: number) => {
    setIsPageLoading(true)

    deletePoint(id).then(() => {
      _pointRefetch().then(() => {
        setIsPageLoading(false)
        setShowModal(false)
      })
    })
  }

  return (
    <S.ModalPointStyled>
      <button onClick={() => {
        setShowModal(true)
      }}>
        <EditIcon style={{ fontSize: '16px' }} />
      </button>

      <ModalGeneral show={showModal} setShow={setShowModal}>
        <form onSubmit={handleEditPoint}>
          <input type='hidden' name='plant_id' defaultValue={selectedPlant.plant_id} />
          <div className='field'>
            <label>Data:</label>
            <input name='point_data' type='date' defaultValue={convertToBRDate(new Date(point.point_data), 'input')} />
          </div>
          <div className='field'>
            <label>Título:</label>
            <input placeholder='Título' name='point_title' defaultValue={point.point_title} type='text' />
          </div>
          <div className='field'>
            <label>Detalhes:</label>
            <input placeholder='Detalhes' name='point_details' defaultValue={point.point_details || ''} type='text' />
          </div>
          <div className='field'>
            <label>Nova imagem:</label>
            <input placeholder='Imagem' name='point_image' type='file' onChange={(ev: any) => {
              if (ev.target?.files.length) {
                setImage({
                  preview: URL.createObjectURL(ev.target.files[0]),
                  raw: ev.target.files[0]
                })
              }
            }} />
          </div>


          {
            image.preview ? (
              <div className='image'>
                <label>Imagem selecionada:</label>
                <span><img alt={'Grow APP'} width={'100%'} src={image.preview} /></span>
              </div>
            ) : (
              (point.point_image !== null && point.point_image !== 'null') ? (
                <div className='image'>
                  <label>Imagem selecionada:</label>
                  <span><img alt={'Grow APP'} width={'100%'} src={`http://grow.app.br/${point.point_image}`} /></span>
                </div>
              ) : (
                <></>
              )
            )
          }




          <div className='actions'>
            {
              point ? (
                <>
                  <button type='button' className='rmvbtn' onClick={() => handleDeletePoint(point?.point_id)}>Apagar</button>
                  <button type='submit'>Alterar</button>
                </>
              ) : (
                <button type='submit'>Adicionar</button>
              )
            }
          </div>
        </form>
      </ModalGeneral>
    </S.ModalPointStyled>
  )
}

export default ModalEditPoint
