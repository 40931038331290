import * as S from './styles'

const Footer = () => {
  return (
    <S.FooterStyled>
      <p>Todos os direitos reservados ®</p>
    </S.FooterStyled>
  )
}

export default Footer
