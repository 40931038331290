import styled from 'styled-components'
import media from 'styled-media-query'

export const AsideFormStyled = styled.form`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 2rem;
	position: relative;

	.close {
		background: ${({ theme }) => theme.colors.second};
		color: ${({ theme }) => theme.colors.main};
    border-radius: 50%;
		font-size: 13px;
		width: 36px;
    height: 36px;
    padding: 0;
    justify-content: center;
    position: absolute;
    right: -28px;
    top: -26px;

		svg {
			font-size: 20px;
		}

		${media.lessThan('medium')`
			right: -14px;
			top: -14px;
    `}
	}

	h2 {
		margin: 0;
	}

	.details {
		display: flex;
		gap: 8px;
		flex-direction: column;
		
		& > div {
			align-items: center;
			display: grid;
			grid-template-columns: calc(50% - 4px) calc(50% - 4px);
			gap: 8px;
	
			&:last-child {
				grid-template-columns: auto;
			}

			.label {
				text-align: right;
			}

			.input {
				align-items: center;

				&.double {
					display: grid;
					gap: 4px;
					grid-template-columns: 54px auto;
				}
			}

			.value {
				padding: 0.5rem;
			}
		}
	}


	input[type='text'],
	input[type='number'],
	input[type='date'],
	textarea,
	select {
    background: #bfc9ce30;
		color: ${({ theme }) => theme.colors.text.light};
    border: 0;
		font-size: 15px;
		font-weight: 400;
		padding: 0.5rem;
		width: 100%;

		&.title {
			font-size: 21px;
			font-weight: 700;
			padding: 1rem;
		}
	}

	select {
		option {
			-webkit-appearence: none;
			background: ${({ theme }) => theme.colors.third};
		}
	}

	textarea {
		line-height: 24px;
		min-height: 184px;

		&::-webkit-scrollbar {
			width: 6px;
		}
		
		&::-webkit-scrollbar-track {
			background: ${({ theme }) => theme.colors.main};
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.colors.first};
			border: 2px solid ${({ theme }) => theme.colors.main};
		}
	}

	button {
		font-size: 15px;
		padding: 1rem;
	}
`
