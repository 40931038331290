import * as S from './styles'
import { ReactNode, useContext } from 'react'
import { Header, Footer, PageLoading } from '../../Organisms'
import { MainContext } from '../../../context/MainContext/MainContext'

interface BaseTemplateProps {
	children?: ReactNode
}

export default function BaseTemplate({
	children
}: BaseTemplateProps) {
	const { isPageLoading } = useContext(MainContext)

	return (
		<S.TemplateStyled>
			{
				isPageLoading ? (
					<PageLoading />
				) : (
					<></>
				)
			}
			<Header />
			<S.MainStyled>
				{children}
			</S.MainStyled>
			<Footer />
		</S.TemplateStyled>
	)
}
