import { PointInterface, PointPostFormType } from '../../interfaces/Point'
import { api } from '../../services/api'

const updatePoint = async (form: PointPostFormType, pointId: number): Promise<PointInterface> => {
	const { data } = await api
		.put(`points/${pointId}`, form, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
		.then((response) => response)

	return data
}

export default updatePoint
