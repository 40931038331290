import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useGetPoint } from '../../hooks/use-get-point'
import { PlantContext } from '../PlantContext/PlantContext'
import { PointInterface, PointType } from '../../interfaces/Point'
import { QueryObserverResult } from 'react-query'

type PointProviderProps = {
	children: ReactNode
}

type PointContextData = {
	_pointRefetch: () => Promise<QueryObserverResult<PointInterface>>
	points: PointType[]
	setPoints: Dispatch<SetStateAction<PointType[]>>
	selectedPoint: PointType
	setSelectedPoint: Dispatch<SetStateAction<PointType>>
}

const PointContext = createContext({} as PointContextData)

const PointProvider = ({ children }: PointProviderProps) => {
	const { selectedPlant } = useContext(PlantContext)
	const { _pointResponse, _pointLoading, _pointRefetch } = useGetPoint(selectedPlant.plant_id)
	const [points, setPoints] = useState<PointType[] | []>([])
	const [selectedPoint, setSelectedPoint] = useState<PointType>(Object)

	useEffect(() => {
		if (!_pointLoading && _pointResponse?.data.length) {
			setPoints(_pointResponse.data)
			setSelectedPoint(_pointResponse.data[0])
		} else {
			setPoints([])
			setSelectedPoint(Object)
		}
	}, [_pointResponse, _pointLoading])

	useEffect(() => {
		if (selectedPlant.plant_id) {
			_pointRefetch()
		}
	}, [selectedPlant, _pointRefetch])

	return (
		<PointContext.Provider
			value={{
				_pointRefetch,
				points,
				setPoints,
				selectedPoint,
				setSelectedPoint
			}}
		>
			{children}
		</PointContext.Provider>
	)
}

export { PointProvider, PointContext }
