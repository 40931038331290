import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react'
import { useGetLogin } from '../../hooks/use-get-login'
import { LoginType } from '../../interfaces/Login'

type MainProviderProps = {
	children: ReactNode
}

type MainContextData = {
	_loginRefetch: () => void
	login: LoginType
	isPageLoading: boolean
	setIsPageLoading: Dispatch<SetStateAction<boolean>>
	showMenu: boolean
	setShowMenu: Dispatch<SetStateAction<boolean>>
}

const MainContext = createContext({} as MainContextData)

const MainProvider = ({ children }: MainProviderProps) => {
	const { _loginResponse, _loginLoading, _loginRefetch } = useGetLogin()
	const [isPageLoading, setIsPageLoading] = useState<boolean>(false)
	const [showMenu, setShowMenu] = useState<boolean>(false)
	const [login, setLogin] = useState<LoginType>(Object)

	useEffect(() => {
		setIsPageLoading(_loginLoading)

		if (!_loginLoading && _loginResponse) {
			setLogin(_loginResponse.data)
		}
	}, [_loginResponse, _loginLoading])

	return (
		<MainContext.Provider
			value={{
				_loginRefetch,
				login,
				isPageLoading,
				setIsPageLoading,
				showMenu,
				setShowMenu,
			}}
		>
			{children}
		</MainContext.Provider>
	)
}

export { MainProvider, MainContext }
