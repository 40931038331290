import * as S from './styles'
import { useContext } from 'react'
import YardIcon from '@mui/icons-material/Yard'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { MainContext } from '../../../context/MainContext/MainContext'
import { GrowthContext } from '../../../context/GrowthContext/GrowthContext'
import { PlantContext } from '../../../context/PlantContext/PlantContext'
import { PlantInterface, PlantPostType } from '../../../interfaces/Plant'
import { convertToBRDate } from '../../../utils/convertToBRDate'
import postPlant from '../../../hooks/use-post-plant'
import deletePlant from '../../../hooks/use-delete-plant'

const PlantMenu = () => {
  const { setIsPageLoading } = useContext(MainContext)
  const { selectedGrowth } = useContext(GrowthContext)
  const { selectedPlant, setSelectedPlant, plants, setIsPlantUpdated, _plantRefetch } = useContext(PlantContext)

  const handleAddNewPlant = () => {
    setIsPageLoading(true)
    const startDate = new Date()

    const newPlant: PlantPostType = {
      plant_strain: "Nova Planta",
      plant_type: "photo_fem",
      plant_qty: 1,
      plant_start_vega: convertToBRDate(startDate, 'input'),
      plant_cycle_vega: 56,
      plant_cycle_bloom: 70,
      plant_seedbank: "Seedstockers",
      plant_details: "- 8 semanas",
      growth_id: selectedGrowth.growth_id
    }

    postPlant(newPlant).then((response: PlantInterface) => {
      if (response.data.length) {
        _plantRefetch().then(() => {
          setTimeout(() => setSelectedPlant(response.data[0]), 500)
        })
      }
      setIsPageLoading(false)
    })
  }

  const handleDeletePlant = (id: number) => {
    if (window.confirm(`Tem certeza que deseja remover a planta ${selectedPlant.plant_strain}?`)) {
      setIsPageLoading(true)
      deletePlant(id).then((data: string) => {
        setIsPageLoading(false)
        setIsPlantUpdated(false)
        _plantRefetch()
      })
    }
  }

  const plantMenuItems = plants.length ? (
    plants?.map(p => (
      <li key={p.plant_id}>
        {
          selectedPlant.plant_id === p.plant_id ? (
            <S.MenuFakeButtonStyled>
              <span>
                <YardIcon /> {p.plant_strain}
              </span>
              <button type='button' className='close' onClick={() => handleDeletePlant(p.plant_id)}><DeleteIcon style={{ fontSize: '16px' }} /></button>
            </S.MenuFakeButtonStyled>
          ) : (
            <S.MenuButtonStyled type="button" onClick={() => setSelectedPlant(p)}>
              <YardIcon /> {p.plant_strain}
            </S.MenuButtonStyled>
          )
        }
      </li>
    ))
  ) : (
    <></>
  )

  return (
    <S.MenuStyled>
      {plantMenuItems}
      {
        selectedGrowth.growth_id ? (
          <li key={plants.length}>
            <S.MenuButtonStyled type="button" onClick={handleAddNewPlant}>
              <AddCircleIcon /> Nova
            </S.MenuButtonStyled>
          </li>
        ) : (
          <></>
        )
      }
    </S.MenuStyled>
  )
}

export default PlantMenu
