import * as S from './styles'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { PointType } from '../../../interfaces/Point'

type PoinntDetailsType = {
  point: PointType[]
}

const PointPopup = (props: PoinntDetailsType) => {
  const { point } = props

  const pointsIds = point.map((p) => {
    return p.point_id
  })

  const pointId = `tooltipId-${pointsIds.join('')}`

  const pointContent = point.length ? (
    point.map((p: PointType, i: number) => {
      return (
        <div key={i}>
          <p>{p.point_title}</p>
          <p>{p.point_details}</p>
          {
            p.point_image ? (
              <img src={`http://grow.app.br/${p.point_image}`} width={'200'} alt={p.point_title} />
            ) : (
              <></>
            )
          }
        </div>
      )
    })
  ) : (
    <></>
  )

  return (
    <>
      <S.PointPopupStyled data-tooltip-id={pointId} data-tooltip-place="top">
        <span><LocationOnIcon /></span>
      </S.PointPopupStyled>
      <S.TooltipStyled id={pointId} place="top-end">
        {pointContent}
      </S.TooltipStyled>
    </>
  )
}

export default PointPopup
