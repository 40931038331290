import { useQuery } from 'react-query'
import { api } from '../../services/api'
import { GrowthInterface } from '../../interfaces/Growth'

async function fetchGetGrowth(): Promise<GrowthInterface> {
	const { data } = await api.get<GrowthInterface>(`growths`)
	return data
}

export function useGetGrowth() {
	const { data, isLoading, error, refetch } = useQuery(
		'growths',
		() => fetchGetGrowth(),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: false,
			enabled: false,
			retry: false
		}
	)

	return {
		_growthResponse: data,
		_growthLoading: isLoading,
		_growthError: error,
		_growthRefetch: refetch
	}
}
