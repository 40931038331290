import * as S from './styles'
import { Hemisphere, Moon } from 'lunarphase-js'
import { convertToBRDate } from "../../../utils/convertToBRDate"
import { PointType } from '../../../interfaces/Point'
import PointPopup from '../PointPopup/pointPopup'

type PlantDetailsType = {
  period: 'v' | 'b'
  start: Date
  weekNum: number
  total: number
  points: PointType[]
}

const WeekPeriod = (props: PlantDetailsType) => {
  const { period, start, weekNum, total, points } = props

  const DaysDate = (start: Date) => {
    const days = new Array(7)

    for (var i = 0; i < days.length; i++) {
      const init = start.setDate(start.getDate() + (i > 0 ? 1 : 0))
      days[i] = new Date(init)
    }

    return days
  }

  const days = DaysDate(new Date(start))
  const today = new Date()
  const classLine = `line_${period}${((weekNum - (weekNum % 4)) / 4) + 1}${weekNum % 2 ? 'b' : 'a'}`

  let countPastDays = 0
  let classWeek = ''
  days.map((d: Date) => {
    if (today.getTime() > d.getTime()) {
      countPastDays++
    }

    if (countPastDays === 7) {
      classWeek = 'past'
    }

    return d
  })

  return (
    <S.WeekPeriodStyled key={weekNum} className={classLine}>
      <h3 className={classWeek}>{`Semana ${weekNum + 1}`}</h3>

      {
        days.map((d: Date, i: number) => {
          const countDays = (i + 1) + (weekNum * 7)

          if (countDays <= total) {
            const point = points?.filter((p: PointType) => {
              const pointData = new Date(p.point_data)
              return pointData.toDateString() === d.toDateString()
            })

            const dayClass = today.toDateString() === d.toDateString() ? (
              `day today ${classLine} ${point.length ? 'hasPoint' : ''}`
            ) : (
              today.getTime() > d.getTime() ? (
                `day past ${classLine} ${point.length ? 'hasPoint' : ''}`
              ) : (
                `day future ${classLine} ${point.length ? 'hasPoint' : ''}`
              )
            )

            return (
              <div className={dayClass} key={i}>
                <span className={'totalDays'}>{countDays}</span>
                <span className={'weekDay'}>{convertToBRDate(d, 'week')}</span>
                {convertToBRDate(d, 'basic')}
                <span className={'moonPhase'}>
                  {Moon.lunarPhaseEmoji(d, {
                    hemisphere: Hemisphere.SOUTHERN,
                  })}
                </span>
                {
                  (point.length) ? (
                    <PointPopup point={point} />
                  ) : (
                    <></>
                  )
                }
              </div>
            )
          }

          return <div key={i} className={'empty'} style={{ background: '#2c262350', borderRadius: '5px' }}></div>
        })
      }
    </S.WeekPeriodStyled>
  )
}

export default WeekPeriod
