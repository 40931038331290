import * as S from './styles'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type ModalGeneralType = {
  children: any
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const ModalGeneral = (props: ModalGeneralType) => {
  const { children, show, setShow } = props
  const [showGeneralModal, setShowGeneralModal] = useState<boolean>(show)

  useEffect(() => {
    setShowGeneralModal(show)
  }, [show])

  return (
    <S.ModalGeneralStyled hidden={!showGeneralModal}>
      <S.ModalGeneralContentStyled>
        <button className='close' type='button' onClick={() => {
          setShowGeneralModal(false)
          setShow(false)
        }}>X</button>
        {children}
      </S.ModalGeneralContentStyled>
      <S.ModalGeneralOverlayStyled className='mg-overlay' onClick={() => {
        setShowGeneralModal(false)
        setShow(false)
      }} />
    </S.ModalGeneralStyled>
  )
}

export default ModalGeneral
