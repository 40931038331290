const theme = {
	colors: {
		main: '#f7edd4',
		first: '#679f92',
		second: '#993636',
		third: '#2c2623',
		fourth: '#1a1512',
		text: {
			light: '#f7edd4',
			normal: '#1a1512',
			dark: '#2c2623'
		},
		days: {
			v: {
				line_1a: '#85997d',
				line_1b: '#b6d7a8',
				line_2a: '#607b81',
				line_2b: '#76a5af',
				line_3a: '#606481',
				line_3b: '#767daf'
			},
			b: {
				line_1a: '#ac9176',
				line_1b: '#f9ca9c',
				line_2a: '#a47474',
				line_2b: '#ea9898',
				line_3a: '#847d98',
				line_3b: '#b4a7d6'
			}
		}
	}
}
export default theme