import * as S from './styles'
import { useContext } from 'react'
import postGrowth from '../../../hooks/use-post-growth'
import { GrowthInterface, GrowthPostType } from '../../../interfaces/Growth'
import { GrowthContext } from '../../../context/GrowthContext/GrowthContext'

const NewGrowth = () => {
  const { _growthRefetch, handleSelectedGrowth } = useContext(GrowthContext)

  const handleAddNewGrowth = () => {
    const growthName = window.prompt("Nome do novo cultivo:", "Cultivo N.0")

    if (growthName?.length) {
      const newGrowth: GrowthPostType = {
        growth_name: growthName,
        growth_soil: "",
        growth_current: 0,
        growth_status: 1
      }

      postGrowth(newGrowth).then((response: GrowthInterface) => {
        _growthRefetch().then(() => {
          handleSelectedGrowth(response.data[0])
        })
      })
    }
  }

  return (
    <S.NewGrowthStyled>
      <button type='button' onClick={handleAddNewGrowth}>Novo Cultivo</button>
    </S.NewGrowthStyled>
  )
}

export default NewGrowth
