import * as S from './styles'
import { useContext } from "react"
import { MainContext } from "../../../context/MainContext/MainContext"
import { UserInterface } from "../../../interfaces/User"
import { LoginPostType } from '../../../interfaces/Login'
import postLogin from "../../../hooks/use-post-login"

const FormLogin = () => {
  const { setIsPageLoading, _loginRefetch } = useContext(MainContext)

  const handleLoginForm = (ev: any) => {
    ev.preventDefault()

    setIsPageLoading(true)

    const formObj: LoginPostType = {
      user_email: ev.target.user_email.value,
      user_pass: ev.target.user_pass.value
    }

    postLogin(formObj).then((response: UserInterface) => {
      if (response.success && response?.data.length) {
        _loginRefetch()
      }

      setIsPageLoading(false)
    })
  }

  return (
    <S.FormLoginStyled onSubmit={handleLoginForm}>
      <h3>Login</h3>
      <p>Se já tiver cadastro, digite seu e-mail e senha abaixo e clique em "Acessar".</p>
      <fieldset>
        <div>
          <input type='text' name='user_email' placeholder='E-mail' defaultValue={'rafalongo@gmail.com'} />
        </div>
        <div>
          <input type='password' name='user_pass' placeholder='Senha' defaultValue={'123456'} />
        </div>
      </fieldset>
      <button type='submit'>Acessar</button>
    </S.FormLoginStyled>
  )
}

export default FormLogin
