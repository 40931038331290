import { PointInterface, PointPostType } from '../../interfaces/Point'
import { api } from '../../services/api'

const postPoint = async (form: any): Promise<PointInterface> => {
	const { data } = await api
		.post(`points`, form, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
		.then((response) => response)

	return data
}

export default postPoint
