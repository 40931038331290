import { PlantInterface, PlantPostType } from '../../interfaces/Plant'
import { api } from '../../services/api'

const updatePlant = async (form: PlantPostType, plantId: number): Promise<PlantInterface> => {
	const { data } = await api
		.put(`plants/${plantId}`, form)
		.then((response) => response)

	return data
}

export default updatePlant
