import styled from 'styled-components'
import media from 'styled-media-query'

export const PeriodStyled = styled.div`
	background: rgba(255, 255, 255, 0.063);
	padding: 0px 1rem 1rem;
	border-radius: 8px;

  ${media.lessThan('medium')`
    padding: 0 0.5rem 0.5rem;
  `}

	h2 {
		background: ${({ theme }) => theme.colors.third};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 0.25rem solid ${({ theme }) => theme.colors.fourth};
    color: ${({ theme }) => theme.colors.text.light};
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    margin: 0px 0px 1rem;
    padding: 1rem 1rem 0.75rem;
	}

	.weeks {
		display: grid;
    grid-template-columns: repeat(4, 1fr);
		gap: 1px;
    
    ${media.lessThan('medium')`
      grid-template-columns: repeat(2, 1fr);
    `}
	}
`
