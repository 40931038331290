import styled from 'styled-components'

export const FooterStyled = styled.footer`
	align-items: center;
	background: ${({ theme }) => theme.colors.fourth};
	color: ${({ theme }) => theme.colors.text.light};
	display: flex;
	height: 60px;
	width: 100%;
	justify-content: center;
`
