import styled from 'styled-components'

export const TemplateStyled = styled.main`
	button {
    align-items: center;
    background: ${({ theme }) => theme.colors.second};
		color: rgb(247, 237, 212);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 16px;
    border: 0px;
    padding: 0.5rem;

    &.rmvbtn {
      background: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.second};
      font-weight: 600;
    }
	}
`

export const MainStyled = styled.main`
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 180px);
`
