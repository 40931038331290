import { PlantInterface, PlantPostType } from '../../interfaces/Plant'
import { api } from '../../services/api'

const postPlant = async (form: PlantPostType): Promise<PlantInterface> => {
	const { data } = await api
		.post(`plants`, form)
		.then((response) => response)

	return data
}

export default postPlant
