import { useQuery } from 'react-query'
import { api } from '../../services/api'
import { LoginType } from '../../types/Login'

async function fetchGetLogout(): Promise<LoginType> {
	const { data } = await api.get<LoginType>(`logout`)
	return data
}

export function useGetLogout() {
	const { data, isLoading, error, refetch } = useQuery(
		'logout',
		() => fetchGetLogout(),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: false,
			enabled: false,
			retry: false
		}
	)

	return {
		_logoutResponse: data,
		_logoutLoading: isLoading,
		_logoutError: error,
		_logoutRefetch: refetch
	}
}
