import * as S from './styles'

const PageLoading = () => {
  return (
    <S.PageLoadingStyled>
      <span>Carregando...</span>
    </S.PageLoadingStyled>
  )
}

export default PageLoading
