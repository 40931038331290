import { useQuery } from 'react-query'
import { api } from '../../services/api'
import { LoginInterface } from '../../interfaces/Login'

async function fetchGetLogin(): Promise<LoginInterface> {
	const { data } = await api.get<LoginInterface>(`login`)
	return data
}

export function useGetLogin() {
	const { data, isLoading, error, refetch } = useQuery(
		'login',
		() => fetchGetLogin(),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: false
		}
	)

	return {
		_loginResponse: data,
		_loginLoading: isLoading,
		_loginError: error,
		_loginRefetch: refetch
	}
}
