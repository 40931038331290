import * as S from './styles'
import { useContext } from 'react'
import { PlantContext } from '../../../context/PlantContext/PlantContext'
import { PlantType } from "../../../interfaces/Plant"
import PlantDetails from '../PlantDetails/plantDetails'
import PlantPeriod from '../PlantPeriod/plantPeriod'

const PlantContent = () => {
  const { selectedPlant, plants } = useContext(PlantContext)

  const plantContentItems = plants.length ? (
    plants?.map((p: PlantType) => (
      <S.SectionStyled key={p.plant_id} hidden={!(selectedPlant.plant_id === p.plant_id)}>
        <aside>
          <PlantDetails plant={p} />
        </aside>

        <div className='content'>
          <PlantPeriod plant={p} period={'v'} />
          <PlantPeriod plant={p} period={'b'} />
        </div>
      </S.SectionStyled>
    ))
  ) : (
    <></>
  )

  return (
    <>
      {plantContentItems}
    </>
  )
}

export default PlantContent
