import styled from 'styled-components'
import media from 'styled-media-query'

export const WelcomeStyled = styled.div`
	align-items: center;
	background: #ffffff20;
	padding: 0.5rem;
	border-radius: 5px;
	display: flex;
	gap: 16px;

	${media.lessThan('medium')`
		order: 1;
	`}

	p {
		font-size: 14px;
		margin: 0;
	}
`
