import * as S from './styles'
import { useContext } from "react"
import { MainContext } from "../../../context/MainContext/MainContext"
import { UserInterface, UserPostType } from "../../../interfaces/User"
import postRegister from '../../../hooks/use-post-register'

const FormRegister = () => {
  const { setIsPageLoading, _loginRefetch } = useContext(MainContext)

  const handleLoginForm = (ev: any) => {
    ev.preventDefault()

    setIsPageLoading(true)

    const formObj: UserPostType = {
      user_name: ev.target.user_name.value,
      user_email: ev.target.user_email.value,
      user_pass: ev.target.user_pass.value,
      user_status: 1
    }

    postRegister(formObj).then((response: UserInterface) => {
      if (response.success && response?.data.length) {
        _loginRefetch()
      }

      setIsPageLoading(false)
    })
  }

  return (
    <S.FormLoginStyled onSubmit={handleLoginForm}>
      <h3>Cadastro</h3>
      <p>Se ainda não tiver cadastro, preencha os campos abaixo e clique em "Cadastrar".</p>
      <fieldset>
        <div>
          <input type='text' name='user_name' placeholder='Nome' />
        </div>
        <div>
          <input type='text' name='user_email' placeholder='E-mail' />
        </div>
        <div>
          <input type='password' name='user_pass' placeholder='Senha' />
        </div>
      </fieldset>
      <button type='submit'>Cadastrar</button>
    </S.FormLoginStyled>
  )
}

export default FormRegister
