import styled from 'styled-components'
import media from 'styled-media-query'

export const SelectGrowthStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;

	${media.lessThan('medium')`
		//
	`}
`

export const SelectStyled = styled.select`
	background: ${({ theme }) => theme.colors.third};
	border: 0;
	color: ${({ theme }) => theme.colors.text.light};
	padding: 0.5rem;
	height: auto;
	outline: none;

	${media.lessThan('medium')`
		order: 2;
	`}
`
