import styled from 'styled-components'
import media from 'styled-media-query'

export const MainMenuStyled = styled.ul`
	display: flex;
	gap: 16px;
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		align-items: center;
		display: flex;
	}

	a {
    background: ${({ theme }) => theme.colors.second};
		color: ${({ theme }) => theme.colors.text.light};
		display: flex;
    align-items: center;
		text-decoration: none;
		font-size: 13px;
		line-height: 16px;
		padding: 0.5rem;
	}

	${media.lessThan('medium')`
		order: 3;

		li {
			width: 100%;

			button,
			a {
				width: 100%;
			}
		}
	`}
`
