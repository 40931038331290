import * as S from './styles'
import { useContext } from 'react'
import ImageIcon from '@mui/icons-material/Image'
import { PointType } from '../../../interfaces/Point'
import { convertToBRDate } from "../../../utils/convertToBRDate"
import { PointContext } from '../../../context/PointContext/PointContext'
import ModalAddPoint from '../ModalAddPoint/modalAddPoint'
import ModalEditPoint from '../ModalEditPoint/modalEditPoint'

const PointDetails = () => {
  const { points } = useContext(PointContext)

  return (
    <S.PointStyled>
      <ul>
        {
          points?.map((p: PointType, i: number) => {
            return (
              <li key={i}>
                <span className='date'>
                  {convertToBRDate(new Date(p.point_data), 'basic')}
                </span>
                <span className='title'>
                  {p.point_title}
                </span>
                {
                  p.point_image !== null && p.point_image !== 'null' ? (
                    <span className='image'><ImageIcon /></span>
                  ) : (
                    <span className='image none'></span>
                  )
                }
                <ModalEditPoint point={p} />
              </li>
            )
          })
        }
      </ul>
      <ModalAddPoint />
    </S.PointStyled>
  )
}

export default PointDetails
