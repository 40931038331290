import { api } from '../../services/api'

const deletePoint = async (pointId: number): Promise<string> => {
	const { data } = await api
		.delete(`points/${pointId}`)
		.then((response) => response)

	return data
}

export default deletePoint
