import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'

export const PointPopupStyled = styled.div`
  background: transparent;
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9000;

  & > span {
    display: flex;
    // width: 7px;
    // height: 7px;
    bottom: 2px;
    right: 1px;
    position: absolute;

    svg {
      color: ${({ theme }) => theme.colors.second};
      font-size: 12px;
    }
  }
`

export const TooltipStyled = styled(Tooltip)`
  && {
    background: #f7edd4;
    color: #1a1512;
    z-index: 9000;
  }
`
