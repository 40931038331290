import { api } from '../../services/api'
import { UserInterface, UserPostType } from '../../interfaces/User'
// import { convertToBase64 } from '../../utils/convertToBase64'

const postRegister = async (form: UserPostType): Promise<UserInterface> => {
	// const emailBase64 = convertToBase64(email)
	// const passwordBase64 = convertToBase64(password)

	const { data } = await api
		.post(`users`, form)
		.then((response) => response)

	return data
}

export default postRegister
