import './App.css'
import theme from './styles/theme'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'styled-components'
import { MainProvider } from './context/MainContext/MainContext'
import { GrowthProvider } from './context/GrowthContext/GrowthContext'
import { PlantProvider } from './context/PlantContext/PlantContext'
import { PointProvider } from './context/PointContext/PointContext'
import AppRoutes from './AppRoutes'

const queryClient = new QueryClient()

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <MainProvider>
          <GrowthProvider>
            <PlantProvider>
              <PointProvider>
                <AppRoutes />
              </PointProvider>
            </PlantProvider>
          </GrowthProvider>
        </MainProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
