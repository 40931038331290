import styled from 'styled-components'
import media from 'styled-media-query'

export const SectionStyled = styled.section`
	display: grid;
	grid-template-columns: 25% calc(75% - 2rem);
	gap: 2rem;
	width: 100%;
	padding: 2rem;
	
	${media.lessThan('medium')`
		grid-template-columns: 100%;
		padding: 0.5rem;
	`}

	&[hidden] {
		display: none;
	}

	aside {
		background: ${({ theme }) => theme.colors.first};
		border-radius: 8px;
		color: ${({ theme }) => theme.colors.text.light};
		display: flex;
		flex-direction: column;
		padding: 1rem;
		position: relative;

		${media.lessThan('medium')`
			padding: 0.5rem;
		`}
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
`
