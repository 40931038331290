import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'
import media from 'styled-media-query'

export const SetCurrentGrowthStyled = styled.div`
	button {
		background: none;
		color: #993636;
		padding: 0;
		
		&.current {
			color: #679f92;
		}

	}

	${media.lessThan('medium')`
		//
	`}
`

export const TooltipStyled = styled(Tooltip)`
  && {
    background: #f7edd4;
    color: #1a1512;
    z-index: 9000;
  }
`
