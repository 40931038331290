import styled from 'styled-components'

export const FormLoginStyled = styled.form`
	background: ${({ theme }) => theme.colors.first};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 2.5rem;
	max-width: 480px;
	flex: 1;

	h3 {
		color: ${({ theme }) => theme.colors.text.light};
		font-size: 21px;
		margin: 0 0 0.5rem;
	}

	p {
		color: ${({ theme }) => theme.colors.text.light};
		margin: 0 0 2.5rem;
	}

	fieldset {
		border: 0;
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 0;
		margin: 0 0 4px;
	}

	input {
		background: ${({ theme }) => theme.colors.third};
		border: 0;
		color: ${({ theme }) => theme.colors.text.light};
		padding: 1rem;
		width: 100%;
		outline: none;

		&::placeholder {
			color: ${({ theme }) => theme.colors.text.light};
			opacity: 0.75;
		}
	}

	button {
		align-self: flex-end;
		align-items: center;
		justify-content: center;
    padding: 1rem;
	}
`
