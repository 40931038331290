import { MainInterface } from '../../interfaces/Main'
import { api } from '../../services/api'

const updateCurrentGrowth = async (growthId: number): Promise<MainInterface> => {
	const { data } = await api
		.put(`growths/current/${growthId}`)
		.then((response) => response)

	return data
}

export default updateCurrentGrowth
