import * as S from './styles'
import { useContext, useState } from 'react'
import { MainContext } from '../../../context/MainContext/MainContext'
import { convertToBRDate } from '../../../utils/convertToBRDate'
import { PlantContext } from '../../../context/PlantContext/PlantContext'
import { PointContext } from '../../../context/PointContext/PointContext'
import postPoint from '../../../hooks/use-post-point'
import ModalGeneral from '../ModalGeneral/modalGeneral'

const ModalAddPoint = () => {
  const { setIsPageLoading } = useContext(MainContext)
  const { selectedPlant } = useContext(PlantContext)
  const { setPoints, _pointRefetch } = useContext(PointContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [image, setImage] = useState({
    preview: '',
    raw: '',
  })

  const handleAddPoint = (ev: any) => {
    ev.preventDefault()
    setPoints([])
    setIsPageLoading(true)

    var data = new FormData(ev.target)
    let point: any = {}
    data.forEach((d, i) => {
      point[i] = d
    })

    postPoint(point).then((response) => {
      if (response.data.length) {
        _pointRefetch().then(() => {
          setIsPageLoading(false)
          setShowModal(false)
        })
      }
    })
  }

  return (
    <S.ModalPointStyled>
      <button onClick={() => setShowModal(true)}>
        Adicionar nova notificação
      </button>

      <ModalGeneral show={showModal} setShow={setShowModal}>
        <form onSubmit={handleAddPoint}>
          <input type='hidden' name='plant_id' defaultValue={selectedPlant.plant_id} />
          <div className='field'>
            <label>Data:</label>
            <input name='point_data' type='date' defaultValue={convertToBRDate(new Date(), 'input')} />
          </div>
          <div className='field'>
            <label>Título:</label>
            <input placeholder='Título' name='point_title' type='text' />
          </div>
          <div className='field'>
            <label>Detalhes:</label>
            <input placeholder='Detalhes' name='point_details' type='text' />
          </div>
          <div className='field'>
            <label>Imagem:</label>
            <input placeholder='Imagem' name='point_image' type='file' onChange={(ev: any) => {
              if (ev.target?.files.length) {
                setImage({
                  preview: URL.createObjectURL(ev.target.files[0]),
                  raw: ev.target.files[0]
                })
              }
            }} />
          </div>
          {
            image.preview ? (
              <div className='image'>
                <label></label>
                <span>
                  <img alt={'Grow APP'} width={'100%'} src={image.preview} />
                </span>
              </div>
            ) : (
              <></>
            )
          }
          <div className='actions'>
            <button type='submit'>Adicionar</button>
          </div>
        </form>
      </ModalGeneral>
    </S.ModalPointStyled>
  )
}

export default ModalAddPoint
