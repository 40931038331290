import styled from 'styled-components'

export const WeekPeriodStyled = styled.div`
	display: grid;
	gap: 1px;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 52px);

	h3 {
		background: ${({ theme }) => theme.colors.fourth};
		border-radius: 5px;
		color: ${({ theme }) => theme.colors.text.light};
		display: flex;
		font-size: 16px;
		grid-column: span 2;
		margin: 0;
		height: 100%;
		align-items: center;
		justify-content: center;

		&.past {
			opacity: 0.75;
		}
	}
	
	.day {
		align-items: center;
		border-radius: 5px;
		color: ${({ theme }) => theme.colors.text.dark};
		display: flex;
		padding: 0.25rem;
		font-size: 13px;
		text-align: center;
		justify-content: center;
		position: relative;
		z-index: 10;

		&.hasPoint {
			z-index: 20;
		
			&:hover {
				z-index: 30;
			}
		}

		&.line_v1a {background: ${({ theme }) => theme.colors.days.v.line_1a};}
		&.line_v1b {background: ${({ theme }) => theme.colors.days.v.line_1b};}
		&.line_v2a {background: ${({ theme }) => theme.colors.days.v.line_2a};}
		&.line_v2b {background: ${({ theme }) => theme.colors.days.v.line_2b};}
		&.line_v3a {background: ${({ theme }) => theme.colors.days.v.line_3a};}
		&.line_v3b {background: ${({ theme }) => theme.colors.days.v.line_3b};}
		
		&.line_b1a {background: ${({ theme }) => theme.colors.days.b.line_1a};}
		&.line_b1b {background: ${({ theme }) => theme.colors.days.b.line_1b};}
		&.line_b2a {background: ${({ theme }) => theme.colors.days.b.line_2a};}
		&.line_b2b {background: ${({ theme }) => theme.colors.days.b.line_2b};}
		&.line_b3a {background: ${({ theme }) => theme.colors.days.b.line_3a};}
		&.line_b3b {background: ${({ theme }) => theme.colors.days.b.line_3b};}
		
		&.past {
			background: ${({ theme }) => theme.colors.third};
			color: #544842;
		}
		&.today {
			background: ${({ theme }) => theme.colors.main};
			box-shadow: 0 0 6px rgba(255, 255, 255, 0.4);
			color: ${({ theme }) => theme.colors.second};
			font-weight: 600;

			&:before,
			&:after {
				content : "";
				border: 2px solid ${({ theme }) => theme.colors.first};
				border-radius: 6px;
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				position: absolute;
				z-index: 0;
			}
			
			&:after {
				border: 1px dashed ${({ theme }) => theme.colors.first};
				border-radius: 5px;
				width: calc(100% - 12px);
				height: calc(100% - 12px);
			}
		}

		.totalDays,
		.weekDay {
			align-items: center;
			display: flex;
			background: ${({ theme }) => theme.colors.second};
			border-bottom-right-radius: 6px;
			color: ${({ theme }) => theme.colors.text.light};
			width: 16px;
			height: 14px;
			-webkit-box-pack: center;
			justify-content: center;
			font-size: 8px;
			font-weight: 500;
			line-height: 8px;
			top: 0px;
			left: 0px;
			position: absolute;
			z-index: 10;
		}

		.weekDay {
			background: rgba(255, 255, 255, 0.27);
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 6px;
			color: ${({ theme }) => theme.colors.text.dark};
			right: 0;
			left: auto;
		}

		.moonPhase {
			display: flex;
			align-items: center;
			justify-content: center;
			filter: grayscale(0.75);
			opacity: 0.5;
			font-size: 28px;
			line-height: 28px;
			width: 100%;
			height: 100%;
			top: 0;
			position: absolute;
			z-index: -1;
		}

		&.past {
			.totalDays,
			.weekDay {
				background: #54484275;
				color: ${({ theme }) => theme.colors.fourth};
			}

			.moonPhase {
				filter: grayscale(1);
				opacity: 0.1;
			}
		}

		&.today {
			.totalDays,
			.weekDay {
				background: ${({ theme }) => theme.colors.first};
				color: ${({ theme }) => theme.colors.text.light};
			}
		}
	}

`
