import * as S from './styles'
import { useContext } from 'react'
import { MainContext } from '../../../context/MainContext/MainContext'
import { PlantContext } from '../../../context/PlantContext/PlantContext'
import { PlantType } from "../../../interfaces/Plant"
import { MoonPhaseLegend, PointDetails } from '../../Molecules'
import { convertToBRDate } from "../../../utils/convertToBRDate"
import updatePlant from '../../../hooks/use-update-plant'

type PlantDetailsType = {
  plant: PlantType
}

const PlantDetails = (props: PlantDetailsType) => {
  const { plant } = props
  const { setIsPageLoading } = useContext(MainContext)
  const { setSelectedPlant, setIsPlantUpdated, _plantRefetch } = useContext(PlantContext)

  /**
   * Calculate start bloom date
   */
  let plant_start = new Date(plant.plant_start_vega)
  let plant_bloom = plant_start.setDate(plant_start.getDate() + plant.plant_cycle_vega)

  const plant_start_vega = new Date(plant.plant_start_vega)
  const plant_start_bloom = new Date(plant_bloom)
  const totalDays = plant.plant_cycle_vega + plant.plant_cycle_bloom
  const plant_start_time = new Date(plant.plant_start_vega)
  const plant_crop_time = plant_start_time.setDate(plant_start_time.getDate() + (totalDays - 1))
  const plant_crop_date = new Date(plant_crop_time)
  const today = new Date()
  const oneDay = 86400000

  const handleUpdatePlant = (ev: any) => {
    ev.preventDefault()
    setIsPageLoading(true)

    var data = new FormData(ev.target)
    let plantForm: any = {}
    data.forEach((d, i) => {
      plantForm[i] = d
    })

    updatePlant(plantForm, plant.plant_id).then((response) => {
      if (response.data.length) {
        _plantRefetch().then(() => {
          setIsPlantUpdated(true)
          setSelectedPlant(plant)
        })
        setIsPageLoading(false)
      }
    })
  }

  return (
    <>
      <S.AsideFormStyled onSubmit={handleUpdatePlant}>
        <h2><input defaultValue={plant.plant_strain} name='plant_strain' type='text' className='title' /></h2>

        <div className='details'>
          <div><span className='label'>Quantidade:</span><span className='input double'> <input defaultValue={plant.plant_qty} name='plant_qty' type='number' /></span></div>
          <div><span className='label'>Seedbank:</span><span className='input'> <input defaultValue={plant.plant_seedbank} name='plant_seedbank' type='text' /></span></div>
          <div>
            <span className='label'>Tipo:</span>
            <span className='input'>
              <select name='plant_type' defaultValue={plant.plant_type}>
                <option value='photo_fem'>Fotoperíodo</option>
                <option value='auto_fem'>Automática</option>
                <option value='photo_reg'>Regular</option>
              </select>
            </span>
          </div>

          <div><span className='label'>Início da vega:</span><span> <input defaultValue={convertToBRDate(plant_start_vega, 'input')} name='plant_start_vega' type='date' /></span></div>
          <div><span className='label'>Tempo de vega:</span><span className='input double'> <input defaultValue={plant.plant_cycle_vega} name='plant_cycle_vega' type='number' /> dias</span></div>
          <div><span className='label'>Início da flora:</span><span className='value'> {convertToBRDate(plant_start_bloom, 'full')}</span></div>
          <div><span className='label'>Tempo de flora:</span><span className='input double'> <input defaultValue={plant.plant_cycle_bloom} name='plant_cycle_bloom' type='number' /> dias</span></div>
          <div><span className='label'>Colheita em:</span><span className='value'> {convertToBRDate(plant_crop_date, 'full')}</span></div>
          {/* <div><span className='label'>Colheita / Total:</span><span className='value'> {`${Math.ceil((plant_crop_date.getTime() - today.getTime()) / oneDay)} / ${totalDays} dias`}</span></div> */}
          <div style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginBottom: '1.5rem'
          }}>{`Faltam ${Math.ceil((plant_crop_date.getTime() - today.getTime()) / oneDay)} dias!`}</div>
          <div><textarea defaultValue={plant.plant_details} name='plant_details'></textarea></div>
        </div>

        <button type='submit'>Salvar</button>
      </S.AsideFormStyled >
      <PointDetails />
      <MoonPhaseLegend />
    </>
  )
}

export default PlantDetails
