import styled from 'styled-components'
import media from 'styled-media-query'

export const ModalGrowthStyled = styled.div`
	//
	${media.lessThan('medium')`
		order: 3;
	`}

	& > button {
		${media.lessThan('medium')`
			width: 100%;
		`}
	}
`

export const ModalStyled = styled.div`
	align-items: center;
	background: #00000075;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 90000;

	&[hidden] {
		display: none;
	}

	${media.lessThan('medium')`
		//	
	`}
`

export const ModalFormStyled = styled.form`
	background: ${({ theme }) => theme.colors.first};
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.text.light};
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 480px;
	height: auto;
	padding: 1rem;
	position: relative;

	${media.lessThan('medium')`
		width: 90%;
	`}

	input[type='text'],
	textarea {
		background: #bfc9ce30;
		color: ${({ theme }) => theme.colors.text.light};
		font-size: 15px;
		line-height: 24px;
		padding: 0.5rem;
		border: 0;
		width: 100%;
	}
	
	textarea {
		height: 264px;
	}
	
	button {
		width: fit-content;
		align-self: flex-end;

		&.close {
			border-radius: 50%;
			height: 32px;
			width: 32px;
			top: -16px;
			right: -16px;
			position: absolute;
		}
	}

	.actions {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	${media.lessThan('medium')`
	`}
`
