import * as S from './styles'
import { Hemisphere, LunarPhase, Moon } from 'lunarphase-js'

type MoonPhasesType = {
  key: LunarPhase
  value: string
}

const MoonPhaseLegend = () => {
  const moonPhases: MoonPhasesType[] = [
    {
      key: LunarPhase['NEW'],
      value: "Nova"
    },
    {
      key: LunarPhase['FULL'],
      value: "Cheia"
    },
    {
      key: LunarPhase['WAXING_CRESCENT'],
      value: "Crescente"
    },
    {
      key: LunarPhase['WANING_GIBBOUS'],
      value: "Minguante Gibosa"
    },
    {
      key: LunarPhase['FIRST_QUARTER'],
      value: "Quarto Crescente"
    },
    {
      key: LunarPhase['LAST_QUARTER'],
      value: "Quarto Minguante"
    },
    {
      key: LunarPhase['WAXING_GIBBOUS'],
      value: "Crescente Gibosa"
    },
    {
      key: LunarPhase['WANING_CRESCENT'],
      value: "Minguante"
    }
  ]

  return (
    <S.MoonListStyled>
      {
        moonPhases.map((m: MoonPhasesType, i: number) => {
          return (
            <li key={i}>
              <span>
                {Moon.emojiForLunarPhase(m.key, {
                  hemisphere: Hemisphere.SOUTHERN
                })}
              </span>
              {m.value}
            </li>
          )
        })
      }
    </S.MoonListStyled>
  )
}

export default MoonPhaseLegend
