import styled from 'styled-components'

export const PointStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	position: relative;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0;
    list-style: none;
    padding: 0;
    margin: 0 0 0.5rem;

    li {
      display: grid;
      gap: 4px;
      grid-template-columns: calc(36% - 12px) calc(64% - 40px) 20px 20px;
      padding: 0.5rem 0.25rem;

      &:nth-child(odd){
        background: #bfc9ce30;
      }
    }
  }

  span {
    align-items: center;
    color: ${({ theme }) => theme.colors.text.light};
    font-size: 15px;
    font-weight: 400;
    padding: 0;
    width: 100%;
    height: 100%;

    &.date,
    &.title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.image {
      align-items: center;
      background: none;
      justify-content: center;
      border-radius: 5px;
      display: flex;
      padding: 0;
      width: 20px;
      height: 20px;
    
      svg {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.main};
      }
      
      &.none {
        background: none;
      }
    }
  }

	button {
    &.save {
      align-items: center;
      font-size: 11px;
      font-weight: 600;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.first};
    }
	}
`
