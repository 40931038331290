import { GrowthInterface, GrowthPutType } from '../../interfaces/Growth'
import { api } from '../../services/api'

const updateGrowth = async (form: GrowthPutType, growthId: number): Promise<GrowthInterface> => {
	const { data } = await api
		.put(`growths/${growthId}`, form)
		.then((response) => response)

	return data
}

export default updateGrowth
