import { api } from '../../services/api'

const deleteGrowth = async (growthId: number): Promise<string> => {
	const { data } = await api
		.delete(`growths/${growthId}`)
		.then((response) => response)

	return data
}

export default deleteGrowth
