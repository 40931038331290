import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { GrowthContext } from '../GrowthContext/GrowthContext'
import { PlantInterface, PlantType } from '../../interfaces/Plant'
import { useGetPlant } from '../../hooks/use-get-plant'

type PlantProviderProps = {
	children: ReactNode
}

type PlantContextData = {
	_plantRefetch: () => Promise<QueryObserverResult<PlantInterface>>
	plants: PlantType[]
	selectedPlant: PlantType
	setSelectedPlant: Dispatch<SetStateAction<PlantType>>
	setIsPlantUpdated: Dispatch<SetStateAction<boolean>>
	handleSelectedPlant: (plant: PlantType) => void
}

const PlantContext = createContext({} as PlantContextData)

const PlantProvider = ({ children }: PlantProviderProps) => {
	const { selectedGrowth } = useContext(GrowthContext)
	const { _plantResponse, _plantLoading, _plantRefetch } = useGetPlant(selectedGrowth.growth_id)
	const [plants, setPlants] = useState<PlantType[] | []>([])
	const [selectedPlant, setSelectedPlant] = useState<PlantType>(Object)
	const [isPlantUpdated, setIsPlantUpdated] = useState<boolean>(false)
	const savedPlant = localStorage.getItem('plant') || null

	const handleSelectedPlant = (plant: PlantType): void => {
		setSelectedPlant(plant)
		localStorage.setItem('plant', plant.plant_id.toString())
	}

	useEffect(() => {
		if (!_plantLoading && _plantResponse?.data.length) {
			setPlants(_plantResponse.data)
			if (!isPlantUpdated) {
				handleSelectedPlant(_plantResponse.data[0])
			}
		} else {
			setPlants([])
			setSelectedPlant(Object)
		}
	}, [_plantResponse, _plantLoading, savedPlant, isPlantUpdated])

	useEffect(() => {
		if (selectedGrowth.growth_id) {
			_plantRefetch()
		}
	}, [selectedGrowth, _plantRefetch])

	return (
		<PlantContext.Provider
			value={{
				_plantRefetch,
				plants,
				selectedPlant,
				setSelectedPlant,
				setIsPlantUpdated,
				handleSelectedPlant
			}}
		>
			{children}
		</PlantContext.Provider>
	)
}

export { PlantProvider, PlantContext }
