import styled from 'styled-components'

export const MoonListStyled = styled.ul`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
  margin: 2rem 0 1rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    gap: 8px;
    font-size: 12px;

    span {
      filter: grayscale(0.75);
    }
  }
`
