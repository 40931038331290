import styled from 'styled-components'
import media from 'styled-media-query'

export const PageLoadingStyled = styled.div`
	background: #ffffff50;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 900000000;
	
	${media.lessThan('medium')`
	`}

	span {
		background: ${({ theme }) => theme.colors.second};
		border-bottom-left-radius: 5px;
		color: ${({ theme }) => theme.colors.text.light};
		font-size: 13px;
		padding: 6px 10px 8px;
		right: 0;
		top: 0;
		position: absolute;
	}
`
