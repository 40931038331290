import styled from 'styled-components'
import media from 'styled-media-query'

export const NewGrowthStyled = styled.div`
	//
	${media.lessThan('medium')`
		order: 4;
	`}
	
	& > button {
		${media.lessThan('medium')`
			width: 100%;
		`}
	}
`
