import styled from 'styled-components'
import media from 'styled-media-query'

export const HeaderStyled = styled.header`
	align-items: center;
	background: ${({ theme }) => theme.colors.fourth};
	color: ${({ theme }) => theme.colors.text.light};
	display: flex;
	height: 120px;
	width: 100%;
	justify-content: space-between;
	padding: 1rem 2rem;
	
	${media.lessThan('medium')`
		padding: 0.5rem;
	`}

	h1 {
    margin: 0;
    padding: 0;
		height: 96%;
    
		img {
			height: 100%;
		}
		
		${media.lessThan('medium')`
			height: 50%;
		`}
	}

	nav {
		align-items: center;
		display: flex;
		gap: 16px;
		height: 48px;

		${media.lessThan('medium')`
			align-items: stretch;
			background: ${({ theme }: any) => theme.colors.fourth};
			flex-direction: column;
			width: 85%;
			height: 100%;
			top: 0;
			padding: 1rem;
			position: fixed;
			z-index: 900;
			transition: all 1s;
			right: -85%;
			
			&.closed {
			}

			&.opened {
				right: 0%;
			}
		`}
	}
`

export const MenuButtonStyled = styled.button`
	&& {
		background: transparent;
		display: none;
		transition: all 1s;
		padding: 0;
		position: absolute;
		z-index: 0;

		${media.lessThan('medium')`
			display: block;
		`}
		
		svg {
			background: ${({ theme }) => theme.colors.second};
			width: 40px;
			height: 40px;
			padding: 0.5rem;
		}
		
		&.opened {
			right: 85%;
			position: fixed;
			z-index: 800;

			&:after {
				content: "";
				background: #00000090;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				position: fixed;
				z-index: -1;
			}
		}

		&.closed {
			right: 0.5rem;
		}
	}
`