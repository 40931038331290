import { api } from '../../services/api'

const deletePlant = async (plantId: number): Promise<string> => {
	const { data } = await api
		.delete(`plants/${plantId}`)
		.then((response) => response)

	return data
}

export default deletePlant
