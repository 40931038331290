import styled from 'styled-components'
import media from 'styled-media-query'

export const MenuStyled = styled.menu`
	align-items: center;
	color: #fff;
	display: flex;
	height: auto;
	width: 100%;
	list-style: none;
	gap: 16px;
	padding: 0 2rem;
	margin: 0;

	${media.lessThan('medium')`
		flex-direction: column;
		padding: 0 0.5rem;
		gap: 0;
	`}

	li {
		${media.lessThan('medium')`
      width: 100%;
    `}
	}
`

export const MenuButtonStyled = styled.button`
	&& {
		background: ${({ theme }) => theme.colors.fourth};
		border-radius: 8px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom: 0.25rem solid ${({ theme }) => theme.colors.first};
		color: ${({ theme }) => theme.colors.first};
		cursor: pointer;
		flex-direction: row;
		gap: 4px;
		margin: 0;
		padding: 1rem 1rem 0.75rem;
	
		${media.lessThan('medium')`
      width: 100%;
			padding: 0.75rem;
			border-bottom-width: 0.175rem;
    `}
	}
`

export const MenuFakeButtonStyled = styled.span`
	&& {
		align-items: center;
		background: ${({ theme }) => theme.colors.first};
		box-shadow: 0px 8px 8px -6px rgba(0,0,0,0.5) inset;
		border-radius: 8px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom: 0.25rem solid ${({ theme }) => theme.colors.first};
		border-color: ${({ theme }) => theme.colors.main};
		color: ${({ theme }) => theme.colors.text.light};
		display: flex;
		font-size: 13px;
		font-weight: 500;
    line-height: 16px;
		flex-direction: row;
		gap: 4px;
		margin: 0;
		padding: 1rem 1rem 0.75rem;
		position: relative;

		& > span {
			align-items: center;
			display: flex;
			gap: 4px;
		}
	
		${media.lessThan('medium')`
      width: 100%;
			padding: 0.75rem;
			border-bottom-width: 0.175rem;
			justify-content: space-between;
    `}

		.close {
			border-radius: 50%;
			padding: 0.25rem;
			margin-left: 2rem;
		}
	}
`
