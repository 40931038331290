import { api } from '../../services/api'
import { GrowthInterface, GrowthPostType } from '../../interfaces/Growth'

const postGrowth = async (form: GrowthPostType): Promise<GrowthInterface> => {
	const { data } = await api
		.post(`growths`, form)
		.then((response) => response)

	return data
}

export default postGrowth
